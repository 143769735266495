<template>
  <v-row id="main2">
    <v-col :cols="12" class="d-flex" v-if="!hide">
      <v-card class="mx-auto" style="max-width: 600px;">
        <v-card-title class="title">
          Blink Counter
        </v-card-title>
        <v-card-text>
          Dry eyes can have long term consequences for your eyes and quality of life. Intense focus on a screen, whether for work or gaming, tends to result in fewer blinks causing worse dry eyes. This website uses your webcam to monitor and record how often you blink. On desktop computers, you can optionally enable notifications so you're reminded to blink if your bpm (blinks per minute) go below 10.
          <br />
          <br />
          <span class="caption">All the processing is done on your web browser, and your webcam contents never leave your device.</span>
          <br />
          <br />
          <v-alert type="warning">
            <span class="font-italic caption">
              Depending on your web browser, covering this page may cause the browser to restrict it's performance resulting in blink detection not working accurately.
            </span>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <span class="caption" v-if="getUpvoteLink">Like the site?  <a :href="getUpvoteLink" target="_blank">Upvote it!</a></span>
          <v-spacer />
          <v-btn text @click="hideIt">
            hide
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col :cols="12" v-if="loading" class="d-flex">
      <v-alert type="warning" dense outlined class="mx-auto">
        Loading model...
      </v-alert>
    </v-col>
    <v-col :cols="12" class="d-flex">
      <div class="mx-auto">
        <div class="canvas-wrapper">
<!--          <canvas id="output"></canvas>-->
          <video id="video" playsinline style="
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        visibility: visible;
        width: auto;
        height: auto;
        max-width: 100vh;
        max-height: 50vh;
        ">
          </video>
        </div>
      </div>
    </v-col>
    <v-col :cols="12" v-if="showLightMessage" class="d-flex">
      <v-alert type="warning" dense outlined class="mx-auto">
        Camera can't find you--how's your lighting?
      </v-alert>
    </v-col>
    <v-col :cols="12" class="d-flex">
      <div class="mx-auto">
        <v-btn @click="reset" class="ml-2 mr-2" :block="isMobile">Reset Timer</v-btn>
        <v-btn v-if="!isMobile" @click="toggleNotifications" class="ml-2 mr-2">{{`${notify ? 'Disable' : 'Enable'} Notifications`}}</v-btn>
        <div v-for="log of logs">
          {{log}}
        </div>
        <v-snackbar v-model="showUpVote">
          Remember to upvote!
          <template>
            <v-btn @click="openUpvote" text>
              Upvote
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-col>
  </v-row>
</template>

<script>

import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-cpu';

import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import * as tf from '@tensorflow/tfjs-core';
import Stats from 'stats.js';

import euclideanDistance from 'euclidean-distance'

import {TRIANGULATION} from '../triangulation';

let self = null

tfjsWasm.setWasmPaths(
    `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${
        tfjsWasm.version_wasm}/dist/`);

const NUM_KEYPOINTS = 468;
const NUM_IRIS_KEYPOINTS = 5;
const GREEN = '#32EEDB';
const RED = '#FF2C35';
const BLUE = '#157AB3';
const YELLOW = '#FFFF00';
let stopRendering = false;

function isMobile() {
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  return isAndroid || isiOS;
}

function distance(a, b) {
  return Math.sqrt(Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2));
}

function drawPath(ctx, points, closePath) {
  const region = new Path2D();
  region.moveTo(points[0][0], points[0][1]);
  for (let i = 1; i < points.length; i++) {
    const point = points[i];
    region.lineTo(point[0], point[1]);
  }

  if (closePath) {
    region.closePath();
  }
  ctx.stroke(region);
}

let model, ctx, videoWidth, videoHeight, video, canvas,
    scatterGLHasInitialized = false, scatterGL, rafID;

// const VIDEO_SIZE = Math.min(500, Math.round(window.innerHeight / 2))//500;
const mobile = isMobile();
// Don't render the point cloud on mobile in order to maximize performance and
// to avoid crowding limited screen space.
const renderPointcloud = mobile === false;
const stats = new Stats();
const state = {
  backend: 'webgl',
  maxFaces: 1,
  triangulateMesh: true,
  predictIrises: true
};

if (renderPointcloud) {
  state.renderPointcloud = true;
}

let alreadySetup = false
function setupDatGui() {
  if(alreadySetup) {
    return
  }
  alreadySetup = true
  // const gui = new dat.GUI();
  // gui.add(state, 'backend', ['webgl', 'wasm', 'cpu'])
  //     .onChange(async backend => {
  //       stopRendering = true;
  //       window.cancelAnimationFrame(rafID);
  //       await tf.setBackend(backend);
  //       stopRendering = false;
  //       requestAnimationFrame(renderPrediction);
  //     });
  //
  // gui.add(state, 'maxFaces', 1, 20, 1).onChange(async val => {
  //   model = await faceLandmarksDetection.load(
  //       faceLandmarksDetection.SupportedPackages.mediapipeFacemesh,
  //       {maxFaces: val});
  // });
  //
  // gui.add(state, 'triangulateMesh');
  // gui.add(state, 'predictIrises');
  //
  // if (renderPointcloud) {
  //   gui.add(state, 'renderPointcloud').onChange(render => {
  //     document.querySelector('#scatter-gl-container').style.display =
  //         render ? 'inline-block' : 'none';
  //   });
  // }
}

async function setupCamera() {
  video = document.getElementById('video');

  const stream = await navigator.mediaDevices.getUserMedia({
    'audio': false,
    'video': {
      facingMode: 'user',
      // Only setting the video to a specified size in order to accommodate a
      // point cloud, so on mobile devices accept the default size.
      // width: Math.round(window.innerHeight / 2),//mobile ? undefined : VIDEO_SIZE,
      // height: Math.round(window.innerHeight / 2)
      // height: mobile ? undefined : VIDEO_SIZE
    },
  });
  video.srcObject = stream;

  return new Promise((resolve) => {
    video.onloadedmetadata = () => {
      resolve(video);
    };
  });
}


function isBlinking(iris, lower, upper) {
  const centerIris = iris[0]
  const lowerLeft = lower[3]
  const lowerRight = lower[4]
  const upperLeft = upper[3]
  const upperRight = upper[4]


  const irisHeight = euclideanDistance(iris[2], iris[4])

  // let distances = []
  // distances.push(euclideanDistance(centerIris, lowerLeft))
  // distances.push(euclideanDistance(centerIris, lowerRight))
  // distances.push(euclideanDistance(centerIris, upperLeft))
  // distances.push(euclideanDistance(centerIris, upperRight))
  const total = euclideanDistance(centerIris, lowerLeft) +
      euclideanDistance(centerIris, lowerRight) +
      euclideanDistance(centerIris, upperLeft) +
      euclideanDistance(centerIris, upperRight)

  const avg = total / 4

  const threshold = irisHeight / 2 * 0.75

  const ret = avg < threshold
  // if(ret) {
  //   console.log(`avg = ${avg}, height = ${threshold}`)
  // }
  return ret
}

let lastBlink = 0
let blinking = false

let lastBlinkAlert = 0

async function renderPrediction() {
  if (stopRendering) {
    return;
  }

  stats.begin();

  const predictions = await model.estimateFaces({
    input: video,
    returnTensors: false,
    flipHorizontal: false,
    predictIrises: state.predictIrises
  });

  self.loading = false

  // ctx.drawImage(
  //     video, 0, 0, videoWidth, videoHeight, 0, 0, canvas.width, canvas.height);

  if (predictions.length > 0) {

    if(self.pausedAt) {
      self.totalPausedTime += (Date.now() - self.pausedAt)
      self.pausedAt = null
    }

    const totalDuration = Date.now() - self.start - self.totalPausedTime
    if(totalDuration > 5000 && self.totalBlinks > 3 && self.showUpVote === null && self.getUpvoteLink !== null) {
      self.showUpVote = true
    }

    // console.log('predictions')
    // console.log(predictions)

    let lookingFor = null

    predictions.forEach(prediction => {

      lookingFor = [
        // prediction.annotations.leftEyeIris[0], //center
        // prediction.annotations.leftEyeIris[1], //right
        // prediction.annotations.leftEyeIris[2], //top
        // prediction.annotations.leftEyeIris[3], //left
        // prediction.annotations.leftEyeIris[4], //bottom

        // prediction.annotations.leftEyeLower0[3],
        // prediction.annotations.leftEyeLower0[4],
        // prediction.annotations.leftEyeUpper0[3],
        // prediction.annotations.leftEyeUpper0[4],
        // prediction.annotations.rightEyeUpper0[3],
        // prediction.annotations.rightEyeUpper0[4],
        // prediction.annotations.rightEyeUpper0[3],
        // prediction.annotations.rightEyeUpper0[4]
      ]
      const leftBlink = isBlinking(prediction.annotations.leftEyeIris, prediction.annotations.leftEyeLower0, prediction.annotations.leftEyeUpper0)
      const rightBlink = isBlinking(prediction.annotations.rightEyeIris, prediction.annotations.rightEyeLower0, prediction.annotations.rightEyeUpper0)
      const blink = leftBlink || rightBlink
      if(blink) {
        if(blinking) {
          // console.log('long blink')
        }
        else if(Date.now() - lastBlink > 500) {
          blinking = true
          lastBlink = Date.now()
          self.totalBlinks++

          const bpm = self.totalBlinks / ((totalDuration) / (60 * 1000))

          let msg = `${bpm.toFixed(2)} blinks per minute, ${self.totalBlinks} total blinks ${new Date().toLocaleTimeString()}`

          if(self) {
            self.logs.unshift(msg)

            if(self.logs.length > 20) {
              self.logs.pop()
            }
          }

          panel.update(bpm, 60)

          console.log(msg)
        }
      } else {
        const bpm = self.totalBlinks / ((totalDuration) / (60 * 1000))
        if(bpm < 10.00) {
          if(Date.now() - lastBlinkAlert > 15000 && totalDuration > 30000) {
            lastBlinkAlert = Date.now()

            if(self.notify) {
              new Notification('Remember to blink!', {
                body: `BPM too low (${bpm.toFixed(0)})!`
              })
            }
          }
        }
        blinking = false
      }
      //
      // const keypoints = prediction.scaledMesh;
      //
      // if (state.triangulateMesh) {
      //   ctx.strokeStyle = GREEN;
      //   ctx.lineWidth = 0.5;
      //
      //   for (let i = 0; i < TRIANGULATION.length / 3; i++) {
      //     const points = [
      //       TRIANGULATION[i * 3], TRIANGULATION[i * 3 + 1],
      //       TRIANGULATION[i * 3 + 2]
      //     ].map(index => keypoints[index]);
      //
      //     drawPath(ctx, points, true);
      //   }
      // } else {
      //   ctx.fillStyle = GREEN;
      //
      //   for (let i = 0; i < NUM_KEYPOINTS; i++) {
      //     const x = keypoints[i][0];
      //     const y = keypoints[i][1];
      //
      //     ctx.beginPath();
      //     ctx.arc(x, y, 1 /* radius */, 0, 2 * Math.PI);
      //     ctx.fill();
      //   }
      // }
      //
      // if (keypoints.length > NUM_KEYPOINTS) {
      //   ctx.strokeStyle = RED;
      //   ctx.lineWidth = 1;
      //
      //   const leftCenter = keypoints[NUM_KEYPOINTS];
      //   const leftDiameterY = distance(
      //       keypoints[NUM_KEYPOINTS + 4], keypoints[NUM_KEYPOINTS + 2]);
      //   const leftDiameterX = distance(
      //       keypoints[NUM_KEYPOINTS + 3], keypoints[NUM_KEYPOINTS + 1]);
      //
      //   ctx.beginPath();
      //   ctx.ellipse(
      //       leftCenter[0], leftCenter[1], leftDiameterX / 2, leftDiameterY / 2,
      //       0, 0, 2 * Math.PI);
      //   ctx.stroke();
      //
      //   if (keypoints.length > NUM_KEYPOINTS + NUM_IRIS_KEYPOINTS) {
      //     const rightCenter = keypoints[NUM_KEYPOINTS + NUM_IRIS_KEYPOINTS];
      //     const rightDiameterY = distance(
      //         keypoints[NUM_KEYPOINTS + NUM_IRIS_KEYPOINTS + 2],
      //         keypoints[NUM_KEYPOINTS + NUM_IRIS_KEYPOINTS + 4]);
      //     const rightDiameterX = distance(
      //         keypoints[NUM_KEYPOINTS + NUM_IRIS_KEYPOINTS + 3],
      //         keypoints[NUM_KEYPOINTS + NUM_IRIS_KEYPOINTS + 1]);
      //
      //     ctx.beginPath();
      //     ctx.ellipse(
      //         rightCenter[0], rightCenter[1], rightDiameterX / 2,
      //         rightDiameterY / 2, 0, 0, 2 * Math.PI);
      //     ctx.stroke();
      //   }
      // }
    });

    // if (renderPointcloud && state.renderPointcloud && scatterGL != null) {
    //   const pointsData = predictions.map(prediction => {
    //     let scaledMesh = prediction.scaledMesh;
    //     return scaledMesh.map(point => ([-point[0], -point[1], -point[2]]));
    //   });
    //
    //   let flattenedPointsData = [];
    //   for (let i = 0; i < pointsData.length; i++) {
    //     flattenedPointsData = flattenedPointsData.concat(pointsData[i]);
    //   }
    //   const dataset = new ScatterGL.Dataset(flattenedPointsData);
    //
    //   if (!scatterGLHasInitialized) {
    //     scatterGL.setPointColorer((i) => {
    //
    //       for(let lf of lookingFor) {
    //         if(lookingFor && predictions[0].scaledMesh[i][0] === lf[0] &&
    //             predictions[0].scaledMesh[i][1] === lf[1] &&
    //             predictions[0].scaledMesh[i][2] === lf[2]
    //         ) {
    //           return YELLOW;
    //         }
    //       }
    //
    //       if (i % (NUM_KEYPOINTS + NUM_IRIS_KEYPOINTS * 2) > NUM_KEYPOINTS) {
    //         return RED;
    //       }
    //       return BLUE;
    //     });
    //     scatterGL.render(dataset);
    //   } else {
    //     scatterGL.updateDataset(dataset);
    //   }
    //   scatterGLHasInitialized = true;
    // }
    self.showLightMessage = false
  } else {
    self.showLightMessage = true
    if(!self.pausedAt) {
      self.pausedAt = Date.now()
    }
  }

  stats.end();
  // rafID = requestAnimationFrame(renderPrediction);
  setTimeout(renderPrediction, 0)
  // renderPrediction()
};

const panel = new Stats.Panel("bpm",
    "#FFFFFF",
    "#000000")

async function main() {
  await tf.setBackend(state.backend);
  // setupDatGui();

  stats.addPanel(panel)
  stats.showPanel(3);  // 0: fps, 1: ms, 2: mb, 3+: custom
  document.getElementById('main2').appendChild(stats.dom);

  await setupCamera();
  video.play();
  videoWidth = video.videoWidth;
  videoHeight = video.videoHeight;
  video.width = videoWidth;
  video.height = videoHeight;

  // canvas = document.getElementById('output');
  // canvas.width = videoWidth;
  // canvas.height = videoHeight;
  // const canvasContainer = document.querySelector('.canvas-wrapper');
  // canvasContainer.style = `width: ${videoWidth}px; height: ${videoHeight}px`;
  //
  // ctx = canvas.getContext('2d');
  // ctx.translate(canvas.width, 0);
  // ctx.scale(-1, 1);
  // ctx.fillStyle = GREEN;
  // ctx.strokeStyle = GREEN;
  // ctx.lineWidth = 0.5;

  model = await faceLandmarksDetection.load(
      faceLandmarksDetection.SupportedPackages.mediapipeFacemesh,
      {maxFaces: state.maxFaces});
  renderPrediction();

  // if (renderPointcloud) {
  //   document.querySelector('#scatter-gl-container').style =
  //       `width: ${VIDEO_SIZE}px; height: ${VIDEO_SIZE}px;`;
  //
  //   scatterGL = new ScatterGL(
  //       document.querySelector('#scatter-gl-container'),
  //       {'rotateOnStart': false, 'selectEnabled': false});
  // }
};

export default {
  name: "BlinkCounter",
  mounted() {
    self = this
    this.getStarted()
  },
  methods: {
    openUpvote() {
      this.showUpVote = false
      setTimeout(() => {
        window.open(this.getUpvoteLink, '_blank')
      }, 200)
    },
    hideIt() {
      this.hide = true
    },
    getStarted() {
      main()
    },
    reset() {
      this.totalPausedTime = 0
      this.start = Date.now()
      this.totalBlinks = 0
      this.logs = []
    },
    toggleNotifications() {
      this.notify = !this.notify
      if(this.notify) {
        Notification.requestPermission()
      }
    }
  },
  data() {
    return {
      referrer: document.referrer,
      showUpVote: null,
      loading: true,
      pausedAt: null,
      totalPausedTime: 0,
      showLightMessage: false,
      isMobile: isMobile(),
      hide: false,
      logs: [],
      notify: false,
      totalBlinks: 0,
      start: Date.now()
    }
  },
  computed: {
    getUpvoteLink() {

      if(this.referrer.indexOf('news.ycombinator.com') !== -1) {
        return 'https://news.ycombinator.com/item?id=28529790'
      } else if(this.referrer.indexOf('reddit.com') !== -1) {
        if(this.$route && this.$route.query && this.$route.query.dryeye) {
          return 'https://www.reddit.com/r/Dryeyes/comments/poawiw/blink_counter/'
        } else {
          return 'https://www.reddit.com/r/lasik/comments/pob1b5/blink_counter_helps_with_dry_eyes/'
        }
      } else if(this.referrer.indexOf('producthunt.com') !== -1) {
        return ''
      } else {
        return null
      }

    }
  }
}
</script>

<style scoped>

</style>